import { Component, OnInit, ViewChild } from '@angular/core';
import Typed from 'typed.js';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  typed: Object;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {
  }

  ngOnInit() {
    const options = {
      strings: [
        'Time', 'Wealth', 'Control', 'Security', 'Confidence', 'Balance', 'Options', 'Stability', 'Results', 'Peace of Mind', 'Comfort', 'Connections'
      ],
      typeSpeed: 100,
      backSpeed: 100,
      showCursor: false,
      cursorChar: '|',
      loop: true
    };

    this.typed = new Typed('#typed-is-awesome', options);
    this.typed = new Typed('#typed-is-awesome2', options);
  }

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

}
