import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss']
})
export class LocationModalComponent implements OnInit {
  isLondon;
  isToronto;
  isWindsor;

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit() {

  }

}
