import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-work-voluntary-lifestyle-modal',
  templateUrl: './work-voluntary-lifestyle-modal.component.html',
  styleUrls: ['./work-voluntary-lifestyle-modal.component.scss']
})
export class WorkVoluntaryLifestyleModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

}
