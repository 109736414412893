import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';
import { CalconicModalComponent } from '../components/calconic-modal/calconic-modal.component';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {
  modalRef: BsModalRef;

  isWealthEssentials: boolean;
  isWealthPremium: boolean;
  isWealthPremiumPlus: boolean;
  isWealthBusiness: boolean;
  isWealthInstitutions: boolean;
  isWealthForAdvisors: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.isWealthEssentials = true;
    this.isWealthPremium = false;
    this.isWealthPremiumPlus = false;
    this.isWealthBusiness = false;
    this.isWealthInstitutions = false;
    this.isWealthForAdvisors = false;
  }

  ngOnInit() {
  }

  wealthEssentials() {
    const newValue = !this.isWealthEssentials;
    this.resetContent();
    this.isWealthEssentials = newValue;
  }

  wealthPremium() {
    const newValue = !this.isWealthPremium;
    this.resetContent();
    this.isWealthPremium = newValue;
  }

  wealthPremiumPlus() {
    const newValue = !this.isWealthPremiumPlus;
    this.resetContent();
    this.isWealthPremiumPlus = newValue;
  }

  wealthBusiness() {
    const newValue = !this.isWealthBusiness;
    this.resetContent();
    this.isWealthBusiness = newValue;
  }
  wealthInstitutions() {
    const newValue = !this.isWealthInstitutions;
    this.resetContent();
    this.isWealthInstitutions = newValue;
  }

  wealthForAdvisors() {
    const newValue = !this.isWealthForAdvisors;
    this.resetContent();
    this.isWealthForAdvisors = newValue;
  }

  resetContent() {
    this.isWealthEssentials = false;
    this.isWealthPremium = false;
    this.isWealthPremiumPlus = false;
    this.isWealthBusiness = false;
    this.isWealthInstitutions = false;
    this.isWealthForAdvisors = false;
  }

  // onAnchorClick() {
  //   this.route.fragment.subscribe ( f => {
  //     console.log(f);
  //     const element = document.querySelector ( '#' + f );
  //     if ( element ) {
  //       element.scrollIntoView ({ block: 'start',  inline: 'start', behavior: 'smooth' });
  //     }
  //   });
  // }

  // just for calculator link destination
  calculator(anchorHash) {
        setTimeout(() => {
            const anchor = document.getElementById(anchorHash);
            if (anchor) {
               anchor.focus();
               anchor.scrollIntoView({ block: 'start',  inline: 'start', behavior: 'smooth' });
            }
          });
     }

  fasterModal() {
    this.modalRef = this.modalService.show(CalconicModalComponent, Object.assign({}, { class: 'default calconic-modal' }));
    this.modalRef.content.fasterYounger = true;
  }
  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

  warrenBuffettModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'default senatus-modal' }));
  }

}
