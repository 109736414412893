import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';
import { CalconicModalComponent } from '../components/calconic-modal/calconic-modal.component';
import { ActivatedRoute } from '@angular/router';
import { ScheduleCallModalComponent } from '../components/Schedule-call-modal/Schedule-call-modal.component';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  modalRef: BsModalRef;

  // allFilter = true;
  // senatusWealthFilter = true;
  // financialEducationFilter = true;
  // investmentPlanningFilter = true;
  // inquiriesFilter = true;
  // privacyFilter = true;
  // miscellaneousFilter = true;
  // insurancePlanningFilter = true;

  senatusWealths: Array<Object>;
  financialEducations: Array<Object>;
  investmentPlannings: Array<Object>;
  inquiries: Array<Object>;
  privacys: Array<Object>;
  miscellaneous: Array<Object>;
  assnsurancePlannings: Array<Object>;
  oneAtATime = true;

  constructor(private modalService: BsModalService, private route: ActivatedRoute,) {

    this.senatusWealths = [
      {
        title: 'How accessible is my team at Senatus Wealth to me and/or my\n' + 'organization?',
        body: '<p class="m-b-10">Typically we counsel our members on a monthly, quarterly, semi-annual or annual basis depending upon their preferences and needs. We are available 24/7/365 by email and in person, over the phone or by video chat within regular business hours Monday-Friday. For more information pertaining to our business hours and locations, please inquire via email- <a class="color4 f-bold" href="mailto:info@senatuswealth.com">info@senatuswealth.com</a>.</p>'
      },
      {
        title: 'What is the definition of “Wealth” at Senatus Wealth?',
        body: '<p class="m-b-10">A wealthy person by definition has historically been measured based upon financial capacity alone.</p><p class="m-b-10">Wealth at our firm is a work voluntary status where balance in all of the elements of wealth, business, health and lifestyle are present. As a Senatus Wealthy individual, you will enjoy the opportunity to have more control over your lifestyle and how you invest your time. You live a well-balanced lifestyle and are fit from a physical, emotional, relational and financial perspective. All of your affairs are organized and in harmony with your personal, family and corporate preferences.</p>'
      },
      {
        title: 'What are my fees?',
        body: '<p class="m-b-10">Your all in fee(s) as it pertains to investment management, investment planning and advisory services range from .5-1.5% depending upon your asset base and account details.</p>'
      },
      {
        title: 'What is included in my fee at Senatus Wealth vs at my bank, mutual fund and/or insurance provider?',
        body: '<p class="m-b-10">Inclusive of your consulting fee, you will receive direct access to all of the solutions in accordance with our offering and your level of membership. Please inquire for specific solutions and the details that pertain to each category.</p><p class="m-b-10">Certain insurance solutions, for example aren\'t included in your all in fee(s). Bear in mind that our fees are at least 3x LESS (Canada) than the purveyor of conventional investment funds at a bank or mutual fund provider in addition to a more involved service model.</p>'
      },
      {
        title: 'How can Senatus Wealth charge almost 75% less for their solutions and still yield a profit?',
        body: '<p class="m-b-10">Our fees are what the fees across the industry should have been decades ago. Due to the traditional fee model within the conventional wealth creation and preservation strategy being so opaque and excessive for decades without an update or market pressure, we believe we are charging a reasonable fee for our advice that is custom tailored to suit modern returns and expectations within the current eco-system.</p><p class="m-b-10">Think of a conventional light bulb vs a modern, energy efficient lightbulb. Our firm has been updated to suit modern standards and we are therefore more efficient from a business model, compensation and results standpoint.</p><p class="m-b-10">For more information about how your current fees affect your results and objectives, please reach out and we will provide you with information about your current plan and some ideas/concepts to consider to make it more efficient.</p>'
      },
      {
        title: 'If the approach costs so much less than the status quo, how good is it?',
        body: '<p class="m-b-10">Well, how great is it to be able to keep 83.4% more of your investment returns than the convention investment management model will allow for?</p><p class="m-b-10">Our approach is mirrored by the strategies of the largest and most successful pension funds in the world, and has been awarded a Nobel Prize. Research the Efficient Market Hypothesis (EMH) and Modern Portfolio Theory (MPT) for a description of the approach.</p>'
      },
      {
        title: 'Are there any fees to cease my relationship with Senatus Wealth?',
        body: '<p class="m-b-10">Consistent with your consulting agreement, as long as you’ve paid your quarterly advisory fees to date there are no other fees that remain outstanding to our firm. You may incur closing or transfer costs to move from one custodian to another but any fees in this regard are handled documented within your agreement(s) with the custodian.</p>'
      },
      {
        title: 'What level of experience do the advisors at Senatus Wealth have and how are they governed?',
        body: '<p class="m-b-10">Each of our accredited advisors have at least 10 years of professional experience within their area of specialty. Our investment managers are governed by the Ontario Securities Commission in Canada and the Securities Exchange Commission in the United States. Our Insurance advisors are governed by the Financial Services Commission of Ontario.</p><p class="m-b-10">These Self-Regulated Organizations perform routine audits and strictly monitor the advice given and activities completed by investment and insurance advisors to ensure that the appropriate qualifications, registrations and continued education obligations are current and compliant with their standards. These entities ensure that compliance standards are upheld within securities and insurance law in accordance with the laws within each region.</p>'
      },
      {
        title: 'What if I’m not a Canadian citizen, or live outside of Canada, can I still employ Senatus Wealth?',
        body: '<p class="m-b-10">Yes, as long as you are a Canadian, American or European citizen we can provide you with wealth, insurance, business, tax and legal counsel.</p>'
      },
      {
        title: 'What methods of interaction will I receive from my Senatus Wealth advisor and how often can I expect to be in contact with them?',
        body: '<p class="m-b-10">In person, over the phone, via email or on video chat via FaceTime or Skype.</p><p class="m-b-10">We are available to our members during business hours in Eastern Standard Time, Monday-Friday from 9AM-7PM. *Availability is subject to change.</p><p class="m-b-10">Our standard review process is available monthly, quarterly and/or annually in accordance with your unique mandate and preferences. You will also receive monthly and quarterly check in emails which will detail recent events providing you with an opportunity to ask questions, and communicate with us outside of our regularly scheduled planning sessions.</p>'
      },
      {
        title: 'What if my needs and goals change? How does my advisory team adapt?',
        body: '<p class="m-b-10">Communication, relationships, and efficient solutions are the lifeblood of our relationship.</p> <p class="m-b-10">Your way of thinking is our way of thinking, so adapting to your vision of a wealthy, work voluntary lifestyle is our focus while providing you with valuable and actionable advice. Through thoughtful conversations, we will ensure that your needs are taken care of as your life continues to evolve.</p>'
      },
      {
        title: 'What happens if there is an interruption in business structure or with my advisor/portfolio manager?',
        body: '<p class="m-b-10">Your accounts will always be accessible through the custodian affiliated with your portfolio manager regardless of solvency. Any change in personnel or business structure within Senatus Wealth or with your portfolio manager, won\'t affect the safety, security or accessibility of your account(s).</p><p class="m-b-10">The portfolio manager manages the money on a daily basis from within the custody account(s) and Senatus Wealth advises you on making important decisions with your money and business as a consultant and independent advisor. Your portfolio manager does have discretionary authority to make investment decisions that will benefit you in accordance with their fiduciary obligation(s) to you and your specific mandate. Senatus Wealth only has access to your account for viewing and interpretation purposes only to provide you with advice. At no time do we have access to your money for any reason. Any contribution/withdrawal decision made on your account(s) must come from you in writing, supported by your signature and confirmation of your personal/corporate information.</p>'
      },
      {
        title: 'Who should I contact if I have any questions?',
        body: '<p class="m-b-10">Your wealth advisor at Senatus Wealth. They are your quarterback of all of your wealth, business and financial needs. Please email <a class="color4 f-bold" href="mailto:hello@senatuswealth.com">hello@senatuswealth.com</a> should you have any questions.</p>'
      },
    ];

    this.financialEducations = [
      {
        title: 'Why choose an independent advisory firm over a bank or mutual fund firm?',
        body: '<p class="m-b-10">Working with an independent firm means that your financial needs can be met without bias or firm related compensation conflict(s) of interest. Certain firms have developed proprietary information models and strategies that their advisors must sell to their clients—These solutions may be suitable but they are often not the best for you in accordance with the fiduciary standard. Independent, unbiased advice is critical to elite financial, and business progress</p>'
      },
      {
        title: 'Why do I need a professional fiduciary advisor? Can’t I build my wealth on my own?',
        body: '<p class="m-b-10">You can build wealth on your own if you choose. However, our experience suggests that working with a professional, fiduciary advisor will ensure that you will remain balanced, focused and motivated toward achieving your objectives, without any unhelpful emotional or cognitive bias(es).</p>'
      },
      {
        title: 'What if my knowledge of personal finance is limited?',
        body: ' <p class="m-b-10">Regardless of your current level of financial literacy within personal finance we will work patiently with you to ensure that you understand the details and feel comfortable with the concepts and decisions you’ll need to make in order to progress financially.</p>'
      },
      {
        title: 'Why am I seeing all of the banks and mutual fund providers slashing the prices of their investment funds all of the sudden?',
        body: '<p class="m-b-10">Due to the disruption of financial technology and the illumination of the moral hazard and excessive fees that exist within conventional personal financial service providers, traditional firms have been forced to adapt to the modern eco-system or face extinction.</p>'
      },
      {
        title: 'What is the difference between Senatus Wealth and my mutual fund provider or bank?',
        body: '<p class="m-b-10">We are the only independent wealth and business advisory firm in the world that can accommodate Canadian, American and European citizens from anywhere in the world. Typically your bank, mutual fund provider and/or insurance company will only provide you with financial solutions within your province, state or country, and only within their proprietary business model. We can access any solution on the market while also being able to create our own solutions as well.</p><p class="m-b-10">For an investment planning standpoint we are entirely fee based so to avoid any bias and compensation conflict of interest. Our mandate is to create and develop more educated, confident and successful human beings around the globe.</p><p class="m-b-10">Other firms are primarily focused on compensation and market share as opposed to meaningful relationships and consistent results.</p>'
      },
      {
        title: 'What is the key to efficient, long term capital appreciation within the capital markets?',
        body: '<p class="m-b-10"><strong>Personal controllables: </strong>Self-awareness, prudent planning, dedication, big picture vision, attitude and transparency regarding goals and desired standard of living.</p><p class="m-b-10"><strong>Advisory controllables: </strong>Working with an independent team of professional, fiduciary advisors that assist you in making intelligent decisions that are made with prudence and are achieved through patience, dedication and consistently over time, while remaining poised in bear markets, intelligent in bull markets and invested long term in a well-diversified manner is critical.</p>'
      },
      {
        title: 'How do I remain on track toward my objectives over time?',
        body: '<p class="m-b-10">Set short, medium and long term goals and become emotionally connected to your reasons for achieving your goals, then automate the process and record/track your progress meticulously.</p>'
      },
      {
        title: 'Why aren\'t more people investing passively vs actively?',
        body: '<p class="m-b-10">Passive investment management is an investment approach chosen by many financial insiders and large institutions.</p><p class="m-b-10">Because the world is becoming more in tune with the return vs fee data, passive management has seen large inflows of capital and currently occupies $4T + of the world’s investment assets under management as of June, 2018. Massive outflows of actively management mutual funds are flowing into passively managed ETFs and other market efficient strategies.</p><p class="m-b-10">Typically active management is recommended by banks and mutual fund companies due to the high trading and commission revenues that active management will generate for the establishment. Billions of dollars of buy/sell trading commissions are generated by these strategies each year and we struggle to identify any real long term benefits to this activity from the standpoint of an investor. Some active management activity is warranted based upon investor preferences, but in most cases the activity doesn’t satisfy the fiduciary standard but rather the suitability standard, which is a major point of confusion for investors.</p>'
      }
    ];

    this.investmentPlannings = [
      {
        title: 'Where is my money and how is it invested?',
        body: '<p class="m-b-10">Your money is invested with a portfolio manager with a fiduciary obligation to you in Canada, the United States and/or Europe, depending upon your investment mandate, residency and citizenship.</p><p class="m-b-10">Your money is invested with prudence and strictly based upon your unique mandate(s) to provide you with safety, capital appreciation, diversification, cash flow and liquidity. Depending upon your unique investment mandate, your capital is invested in passive, active and/or an alternative investment environment. For more detailed information on your account holdings, allocation and strategy, please inquire directly.</p><p class="m-b-10">The location of your capital is in the custody account(s) of the custodian affiliated with your portfolio manager. For example, if your investment capital is invested by a Canadian portfolio manager the capital may be at NBCN, RBC, TD Bank, Fidelity and/or credential- all world class and ultra-safe custodial entities. If your capital is invested by an American portfolio manager the assets may be at APEX, Betterment or Bank of America depending upon the custodial relationship between the registered entity and the custodian. For more information pertaining to the location of your capital, please inquire directly.</p>'
      },
      {
        title: 'How can I access or view my account(s)?',
        body: '<p class="m-b-10">In person, over the phone or online on any device with access to the internet from anywhere in the world through the online portal provided by your portfolio manager and/or custodian.</p>'
      },
      {
        title: 'What kind of reporting is available to me as it pertains to my account(s)?',
        body: '<p class="m-b-10">Depending upon your portfolio manager, you will receive monthly, quarterly and/or annual reports pertaining to the holdings, allocation, performance, fees and tax details of your account(s).</p>'
      },
      {
        title: 'How can I make contributions or withdrawals to/from my account(s)?',
        body: '<p class="m-b-10">In person or online. Please inquire directly so we can walk you through the experience and ensure that your request is handled in a complaint and efficient manner.</p>'
      },
      {
        title: 'What accounts can Senatus Wealth accommodate?',
        body: '<p class="m-b-10">Our portfolio managers can accommodate every registered, non-registered, investment and alternative investment account available on the market today within North America and Europe.</p>'
      },
      {
        title: 'How do I transfer my investments from my current financial institution to the care of Senatus Wealth?',
        body: '<p class="m-b-10">Depending upon your residency and your investment mandate, we will assist you with transferring your investment capital from your current financial institution to the appropriate custodian at one of our portfolio manager partner firms.</p><p class="m-b-10">Typically, transfer documentation as well as various investment management agreements will require your review and signature before the transfer of investment funds can occur. Thankfully, once these documents are in place future contributions can be made through the ease of modern technology from anywhere in the world.</p><p class="m-b-10">For a detailed description of the transfer process, please reach out and we will walk you through the process step by step.</p>'
      },
      {
        title: 'How long does it take to transfer my money to the care of Senatus Wealth?',
        body: '<p class="m-b-10">Typically within 15-20 business days your account should transfer from your current financial institution to the portfolio manager you’ve chosen to work with within our syndicate. If your account is in cash at the time of the transfer request, your funds should arrive within 3-5 business days. There may be delays associated with and caused by your current financial institution that we can and will resolve with you along the way.</p>'
      },
      {
        title: 'Does Senatus Wealth have account/net worth minimums to work together?',
        body: '<p class="m-b-10">If you are a Canadian citizen, we can work with you so long as you have $100 000.00 CAD or more to invest from a wealth management standpoint and/or a need for insurance planning. Please visit our membership section for more details, or reach out if you have any additional questions.</p><p class="m-b-10">If you are an American the same rules apply, however the minimums are $1 000 000.00 USD or more.</p>'
      },
      {
        title: 'How are my investment returns calculated?',
        body: '<p class="m-b-10">By measuring the amount of capital that you’ve contributed to your account(s) from the inception date that the capital arrived under our management, to the current value of your capital at the end of the most recent trading day. This is known as your money weighted return. Many firms will provide their clients with time weighted returns which only measure investment returns on a quarterly or annual basis, which isn\'t an accurate representation of true investment results.</p>'
      }
    ];

    this.assnsurancePlannings = [
      {
        title: 'How can I create tax efficient wealth through assurance solutions?',
        body: '<p class="m-b-10">Assurance solutions provide a tax effective, stable dividend that is paid based on the equity balance within certain policies (whole life, universal life). Regardless of the performance of the traditional investment market, insurance companies have historically paid policy owners a policy dividend between 6-9% per year, every year since inception. Assurance solutions also provide an investor with the luxury of being able to leverage cash values by up to 10x, with 100% loan to value.</p><p class="m-b-10">Additionally to the above, assurance solutions pay tax free benefits at the time of claim, and if the policy has been in force for an adequate amount of time, the amount the investor has paid into the policy is less than the cash surrender and benefit value(s), effectively making the policy “free” for the investor. Assurance solutions such as the above represent a very stable asset class and an effective wealth preservation, estate planning and risk mitigation tool for all individuals and/or their corporation(s). For more information about wealth creation through assurance solutions or to obtain a quote, please reach out to <a class="color4 f-bold" href="mailto:hello@senatuswealth.com">hello@senatuswealth.com</a></p>'
      }
    ];
    this.privacys = [
      {
        title: 'How is my information protected?',
        // body: '<p>Please review our <a class="color4 f-bold" href="legal/privacy-policy" fragment="privacy">privacy policy</a></p>'
      }
    ];
    //
    // this.inquiries = [
    //   {
    //     title: 'Who should I contact if I have any questions?',
    //     body: '<p>Your wealth advisor at Senatus Wealth. They are your quarterback of all of your wealth, business and financial needs. Please email <a class="color4 f-bold" href="mailto:hello@senatuswealth.com">hello@senatuswealth.com</a> should you have any questions.</p>'
    //   },
    //   {
    //     title: 'Are there any fees to cease my relationship with Senatus Wealth?',
    //     body: '<p>Consistent with your consulting agreement, as long as you’ve paid your quarterly advisory fees to date there are no other fees that remain outstanding to our firm. You may incur closing or transfer costs to move from one custodian to another but any fees in this regard are handled documented within your agreement(s) with the custodian.</p>'
    //   }
    // ];



    // this.miscellaneous = [
    //   {
    //     title: 'What if my needs and goals change? How does my advisory team adapt?',
    //     body: '<p class="m-b-10">Communication, relationships, and efficient solutions are the lifeblood of our relationship.</p><p class="m-b-10">Your way of thinking is our way of thinking, so adapting to your vision of a wealthy, work voluntary lifestyle is our focus while providing you with valuable and actionable advice. Through thoughtful conversations, we will ensure that your needs are taken care of as your life continues to evolve.</p>'
    //   }
    // ];

  }

  ngOnInit() {}

  // getAllFilter() {
  //   this.resetFilter(true);
  // }

  // getSenatusWealthFilter() {
  //   const newValue = false;
  //   this.resetFilter(false);
  //   this.senatusWealthFilter = !newValue;
  // }

  // getFinancialEducationFilter() {
  //   const newValue = false;
  //   this.resetFilter(false);
  //   this.financialEducationFilter = !newValue;
  // }

  // getInvestmentPlanningFilter() {
  //   const newValue = false;
  //   this.resetFilter(false);
  //   this.investmentPlanningFilter = !newValue;
  // }

  // getInquiriesFilter() {
  //   const newValue = false;
  //   this.resetFilter(false);
  //   this.inquiriesFilter = !newValue;
  // }

  // getPrivacyFilter() {
  //   const newValue = false;
  //   this.resetFilter(false);
  //   this.privacyFilter = !newValue;
  // }

  // getMiscellaneousFilter() {
  //   const newValue = false;
  //   this.resetFilter(false);
  //   this.miscellaneousFilter = !newValue;
  // }

  // getInsurancePlanningFilter() {
  //   const newValue = false;
  //   this.resetFilter(false);
  //   this.insurancePlanningFilter = !newValue;
  // }

  // resetFilter(boolean) {
  //   this.allFilter = boolean;
  //   this.senatusWealthFilter = boolean;
  //   this.financialEducationFilter = boolean;
  //   this.investmentPlanningFilter = boolean;
  //   this.inquiriesFilter = boolean;
  //   this.privacyFilter = boolean;
  //   this.miscellaneousFilter = boolean;
  //   this.insurancePlanningFilter = boolean;
  // }
  onAnchorClick() {
    this.route.fragment.subscribe ( f => {
      const element = document.querySelector ( '#' + f );
      if ( element ) {
        element.scrollIntoView ({ block: 'start',  inline: 'start', behavior: 'smooth' });
      }
    });
  }
  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

  fasterModal() {
    this.modalRef = this.modalService.show(CalconicModalComponent, Object.assign({}, { class: 'default calconic-modal' }));
    this.modalRef.content.fasterYounger = true;
  }

  cashFlowModal() {
    this.modalRef = this.modalService.show(CalconicModalComponent, Object.assign({}, { class: 'default calconic-modal' }));
    this.modalRef.content.cashFlow = true;
  }

  scheduleCallModal() {
    this.modalRef = this.modalService.show(ScheduleCallModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

}
