import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CashFlowModalComponent } from '../cash-flow-modal/cash-flow-modal.component';
import { HistoricalReturnsModalComponent } from '../historical-returns-modal/historical-returns-modal.component';
import { AssurancePlanningModalComponent } from '../assurance-planning-modal/assurance-planning-modal.component';

@Component({
  selector: 'app-related-materials-modal',
  templateUrl: './related-materials-modal.component.html',
  styleUrls: ['./related-materials-modal.component.scss']
})
export class RelatedMaterialsModalComponent implements OnInit {
  modalRef: BsModalRef;
  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService) {
  }

  ngOnInit() {

  }
  cashFlowModal() {
    this.modalRef = this.modalService.show(CashFlowModalComponent, Object.assign({}, { class: 'default cash-flow-modal' }));
  }
  historicalModal() {
    this.modalRef = this.modalService.show(HistoricalReturnsModalComponent, Object.assign({}, { class: 'default historical-returns-modal' }));
  }
  AssurancePlanningModal() {
    this.modalRef = this.modalService.show(AssurancePlanningModalComponent, Object.assign({}, { class: 'default assurance-planning-modal' }));
  }

}
