import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';
import { WorkVoluntaryLifestyleModalComponent } from '../components/work-voluntary-lifestyle-modal/work-voluntary-lifestyle-modal.component';
import { LocationModalComponent } from '../components/location-modal/location-modal.component';

@Component({
  selector: 'app-whoweare',
  templateUrl: './whoweare.component.html',
  styleUrls: ['./whoweare.component.scss']
})
export class WhoweareComponent implements OnInit {
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
  }

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

  teamModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'default team-modal' }));
  }

  londonLocationModal() {
    this.modalRef = this.modalService.show(LocationModalComponent, Object.assign({}, { class: 'default location-modal' }));
    this.modalRef.content.isLondon = true;
  }

  torontoLocationModal() {
    this.modalRef = this.modalService.show(LocationModalComponent, Object.assign({}, { class: 'default location-modal' }));
    this.modalRef.content.isToronto = true;
  }

  windsorLocationModal() {
    this.modalRef = this.modalService.show(LocationModalComponent, Object.assign({}, { class: 'default location-modal' }));
    this.modalRef.content.isWindsor = true;
  }

  workVoluntaryLifestyleModal() {
    this.modalRef = this.modalService.show(WorkVoluntaryLifestyleModalComponent, Object.assign({}, { class: 'default work-voluntary-modal' }));
  }

  normModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'default the-norm-modal' }));
  }

}
