import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-faster-younger-calculator',
  templateUrl: './faster-younger-calculator.component.html',
  styleUrls: ['./faster-younger-calculator.component.scss']
})
export class FasterYoungerCalculatorComponent implements OnInit {
  constructor( ) {
  }

  ngOnInit() {

  }

}
