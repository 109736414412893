import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { OverviewComponent } from './overview/overview.component';
import { FasterComponent } from './faster/faster.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ProcessComponent } from './process/process.component';
import { InsiderOverviewComponent } from './insider-overview/insider-overview.component';
import { MembershipComponent } from './membership/membership.component';
import { WhoweareComponent } from './whoweare/whoweare.component';
import { CareersComponent } from './careers/careers.component';
import { ResourcesComponent } from './resources/resources.component';
import { Page404Component } from './Page404/page-404.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SafetyOfYourCapitalComponent } from './safety-of-your-capital/safety-of-your-capital.component';
import { LegalComponent } from './legal/legal.component';
import { RelatedMaterialComponent } from './related-material/related-material.component';
import { FasterYoungerCalculatorComponent } from './faster-younger-calculator/faster-younger-calculator.component';
import { CashFlowCalculatorComponent } from './cash-flow-calculator/cash-flow-calculator.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'overview',
    component: OverviewComponent
  },
  {
    path: 'faster',
    component: FasterComponent
  },
  {
    path: 'solutions',
    component: SolutionsComponent
  },
  {
    path: 'process',
    component: ProcessComponent
  },
  {
    path: 'insider-overview',
    component: InsiderOverviewComponent
  },
  {
    path: 'membership',
    component: MembershipComponent
  },
  {
    path: 'whoweare',
    component: WhoweareComponent
  },
  {
    path: 'careers',
    component: CareersComponent
  },
  {
    path: 'resources',
    component: ResourcesComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'safety-of-your-capital',
    component: SafetyOfYourCapitalComponent
  },
  {
    path: 'legal/privacy-policy',
    component: LegalComponent
  },
  {
    path: 'legal/safety-of-your-capital',
    component: LegalComponent
  },
  {
    path: 'relatedMaterial',
    component: RelatedMaterialComponent
  },
  {
    path: 'faster-younger-calculator',
    component: FasterYoungerCalculatorComponent
  },
  {
    path: 'cash-flow-calculator',
    component: CashFlowCalculatorComponent
  },
  {
    path: '**',
    component: Page404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})

export class AppRoutingModule { }

// export const AppRoutingModule = RouterModule.forRoot(routes);
// export { NgModule };
