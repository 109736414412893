import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-get-started-modal',
  templateUrl: './Schedule-call-modal.component.html',
  styleUrls: ['./Schedule-call-modal.component.scss']
})
export class ScheduleCallModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {

  }

  ngOnInit() {

  }

}
