import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';
import { WorkVoluntaryLifestyleModalComponent } from '../components/work-voluntary-lifestyle-modal/work-voluntary-lifestyle-modal.component';
import { WorkVoluntaryFundModalComponent } from '../components/work-voluntary-fund-modal/work-voluntary-fund-modal.component';
import { AssurancePlanningModalComponent } from '../components/assurance-planning-modal/assurance-planning-modal.component';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  ngOnInit() {}

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

  workVoluntaryLifestyleModal() {
    this.modalRef = this.modalService.show(WorkVoluntaryLifestyleModalComponent, Object.assign({}, { class: 'default work-voluntary-modal' }));
  }

  solutionsModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'default solutions-modal' }));
  }
  workVoluntaryFundModal() {
    this.modalRef = this.modalService.show(WorkVoluntaryFundModalComponent, Object.assign({}, { class: 'default work-voluntary-modal' }));
  }
  AssurancePlanningModal() {
    this.modalRef = this.modalService.show(AssurancePlanningModalComponent, Object.assign({}, { class: 'default assurance-planning-modal' }));
  }

  closeModal() {
    this.modalService._hideModal( 1);
  }
}
