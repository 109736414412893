import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-cash-flow-calculator',
  templateUrl: './cash-flow-calculator.component.html',
  styleUrls: ['./cash-flow-calculator.component.scss']
})
export class CashFlowCalculatorComponent implements OnInit {
  constructor( ) {
  }

  ngOnInit() {

  }

}
