import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AccordionModule, CarouselModule, CarouselConfig, ModalModule } from 'ngx-bootstrap';
import { Angulartics2Module } from 'angulartics2';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { OverviewComponent } from './overview/overview.component';
import { FasterComponent } from './faster/faster.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ProcessComponent } from './process/process.component';
import { InsiderOverviewComponent } from './insider-overview/insider-overview.component';
import { MembershipComponent } from './membership/membership.component';
import { WhoweareComponent } from './whoweare/whoweare.component';
import { CareersComponent } from './careers/careers.component';
import { ResourcesComponent } from './resources/resources.component';
import { Page404Component } from './Page404/page-404.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SafetyOfYourCapitalComponent } from './safety-of-your-capital/safety-of-your-capital.component';

import { GetStartedModalComponent } from './components/get-started-modal/get-started-modal.component';
import { CashFlowModalComponent } from './components/cash-flow-modal/cash-flow-modal.component';
import { HistoricalReturnsModalComponent } from './components/historical-returns-modal/historical-returns-modal.component';
import { WorkVoluntaryLifestyleModalComponent } from './components/work-voluntary-lifestyle-modal/work-voluntary-lifestyle-modal.component';
import { CalconicModalComponent } from './components/calconic-modal/calconic-modal.component';
import { LocationModalComponent } from './components/location-modal/location-modal.component';

import { HtmlSanitizerPipe } from './pipes/html-sanitizer.pipe';
import { RelatedMaterialsModalComponent } from './components/related-materials-modal/related-materials-modal.component';
import { WorkVoluntaryFundModalComponent } from './components/work-voluntary-fund-modal/work-voluntary-fund-modal.component';
import { LegalComponent } from './legal/legal.component';
import { AssurancePlanningModalComponent } from './components/assurance-planning-modal/assurance-planning-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScheduleCallModalComponent } from './components/Schedule-call-modal/Schedule-call-modal.component';
import { RelatedMaterialComponent } from './related-material/related-material.component';
import { FasterYoungerCalculatorComponent } from './faster-younger-calculator/faster-younger-calculator.component';
import { CashFlowCalculatorComponent } from './cash-flow-calculator/cash-flow-calculator.component';


@NgModule({
  entryComponents: [
    GetStartedModalComponent,
    ScheduleCallModalComponent,
    WorkVoluntaryLifestyleModalComponent,
    CashFlowModalComponent,
    HistoricalReturnsModalComponent,
    WorkVoluntaryFundModalComponent,
    CalconicModalComponent,
    LocationModalComponent,
    RelatedMaterialsModalComponent,
    LegalComponent,
    AssurancePlanningModalComponent,
    RelatedMaterialComponent,
    FasterYoungerCalculatorComponent,
    CashFlowCalculatorComponent

  ],
  declarations: [
    AppComponent,
    HomeComponent,
    OverviewComponent,
    FasterComponent,
    SolutionsComponent,
    ProcessComponent,
    InsiderOverviewComponent,
    MembershipComponent,
    WhoweareComponent,
    CareersComponent,
    ResourcesComponent,
    Page404Component,
    PrivacyPolicyComponent,
    SafetyOfYourCapitalComponent,
    AssurancePlanningModalComponent,
    LegalComponent,
    GetStartedModalComponent,
    CashFlowModalComponent,
    HistoricalReturnsModalComponent,
    WorkVoluntaryLifestyleModalComponent,
    WorkVoluntaryFundModalComponent,
    CalconicModalComponent,
    LocationModalComponent,
    HtmlSanitizerPipe,
    RelatedMaterialsModalComponent,
    ScheduleCallModalComponent,
    RelatedMaterialComponent,
    FasterYoungerCalculatorComponent,
    CashFlowCalculatorComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    Angulartics2Module.forRoot({ gtm: { userId: 'UA-130626523-1' }}),

  ],
  exports: [
    HtmlSanitizerPipe
  ],
  providers: [
    {
      provide: CarouselConfig, useValue: {
        interval: 3500,
        noPause: true,
        showIndicators: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
