import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-assurance-planning-modal',
  templateUrl: './assurance-planning-modal.component.html',
  styleUrls: ['./assurance-planning-modal.component.scss']
})
export class AssurancePlanningModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

}
