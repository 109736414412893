import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GetStartedModalComponent } from '../get-started-modal/get-started-modal.component';

@Component({
  selector: 'app-work-voluntary-fund-modal',
  templateUrl: './work-voluntary-fund-modal.component.html',
  styleUrls: ['./work-voluntary-fund-modal.component.scss']
})
export class WorkVoluntaryFundModalComponent implements OnInit {
  modalRef: BsModalRef;

  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService) {}

  ngOnInit() {}

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

}
