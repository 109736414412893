import { Component, OnInit } from '@angular/core';
import {  trigger, state, style, transition, animate } from '@angular/animations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';
import { WorkVoluntaryLifestyleModalComponent } from '../components/work-voluntary-lifestyle-modal/work-voluntary-lifestyle-modal.component';

@Component({
  selector: 'app-insider-overview',
  templateUrl: './insider-overview.component.html',
  styleUrls: ['./insider-overview.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179.9deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})
export class InsiderOverviewComponent implements OnInit {
  testimonials: Array<Object>;
  noWrapSlides = false;
  commonQuestions: Array<Object>;
  modalRef: BsModalRef;
  flip1: string = 'inactive';
  flip2: string = 'inactive';
  flip3: string = 'inactive';
  flip4: string = 'inactive';
  flip5: string = 'inactive';
  flip6: string = 'inactive';
  flip7: string = 'inactive';
  flip8: string = 'inactive';
  flip9: string = 'inactive';

  constructor(private modalService: BsModalService) { }

  ngOnInit() {

    this.testimonials = [
      {
        title: 'Michael G, Business Owner',
        body: 'I’ve been looking for a firm like Senatus Wealth. Senatus has just as much skin in the game as I do and has been updated to meet all of my needs as an entrepreneur.'
      },
      {
        title: 'Rob and Mary L, Ultra-High Net Worth Family',
        body: 'We weren’t looking to make a change with our investments having been with the bank for so long and we thought everything was in immaculate order. Senatus Wealth opened our eyes to some very big opportunities for growth within our plan and have worked diligently with us to capitalize on these opportunities. We’re really happy we followed the guidance of Senatus Wealth and didn\'t dismiss their initial commentary.'
      },
      {
        title: 'John W, Business Owner',
        body: 'The way Senatus Wealth is able to integrate with my family members and other professional advisors is seamless and has made our life much more organized.'
      },
      {
        title: 'Bruce M, Business Owner',
        body: 'I’ve always been impressed by the knowledge, skill-set and level of service I receive from my team at Senatus Wealth. They understand risk at a very high level and propose valuable new ideas for my partners and I to consider.'
      },
      {
        title: 'Mark & Denise, Professional Corporation- Dentistry',
        body: 'Senatus made sure our money is safe and that we are comfortable with the direction of our investments. We know that whenever we need our money we can access it without a delay or nickel and dime fees.'
      },
      {
        title: 'Paul A, Partner, Private Accounting Firm',
        body: 'The partnership between Senatus Wealth and our firm has been helpful to our clients that require wealth management advice along with our tax planning services.'
      },
      {
        title: 'Ben B, Partner, Professional Corporation - Law',
        body: 'The independence and flexibility of the Senatus Wealth platform has made it much easier for our firm to focus on our core competency as legal advisors, while providing our clients with ancillary services that they need to maximize their wealth.'
      },
      {
        title: 'Bob and Barb L, High-Net Worth Investors',
        body: 'Our previous advisor didn\'t take the time to explain our fees. We had no idea we were giving away more than 50% of our returns to fees. Senatus assisted us in correcting this problem and we are now enjoying better results than ever before.'
      },
      {
        title: 'Mark and Andrea R, High-Net Worth Investors',
        body: 'The value that Senatus Wealth delivers is worth much more than the price we pay them.'
      },
      {
        title: 'Daniel G, Retired Professional Athlete',
        body: 'I know Senatus Wealth will always do the right thing for me regardless of compensation.'
      },
      {
        title: 'Bill G, Professional Corporation - Medicine',
        body: 'As a surgeon I’m extremely busy. My team at Senatus Wealth went out of their way to accommodate my schedule and take care of everything for my family and I. Now, I have more time for my personal interests and feel a greater sense of comfort with the direction of our family wealth.'
      },
      {
        title: 'Jeremy S, Entrepreneur',
        body: 'The experience that Senatus Wealth took me through while I transitioned from my previous advisor was faster and easier than I expected. I really enjoyed their use of technology along with a human touch to transfer my accounts and build my plan. The ability to utilize technology and also to have access to a human advisor that will help me to address all of the important details is a perfect fit for me.',
      },
      {
        title: 'Chris E, Business Owner',
        body: 'My team at Senatus Wealth was instrumental in developing my financial literacy as an investor. As a business owner, I often get caught up in my day to day tasks and had blindly trusted my former advisory firm to handle all of my financial affairs. Until I was introduced to Senatus Wealth by a friend of mine, I had no idea how many tax and estate planning elements were missing from my plan.'
      }
    ];

    // this.commonQuestions = [
    //   {
    //     title: 'Maximize all opportunites.',
    //     question: 'Am I maximizing all of the opportunities available to me from a tax and legal standpoint as an owner of a corporation in North America?'
    //   },
    //   {
    //     title: 'Create a remarkable legacy.',
    //     question: 'How can I create a remarkable legacy for my family and corporation while I’m alive and after I’m gone?'
    //   },
    //   {
    //     title: 'Mitigate life risks.',
    //     question: 'Have I accounted for the impact that unexpected life events such as, illness, disability, and death could have on me, my family and corporate interests?'
    //   },
    //   {
    //     title: 'Support future generations indefinitely.',
    //     question: 'Does my net worth support future generations the way that I want it to?'
    //   },
    //   {
    //     title: 'Implement efficient employee Pension & Benefit programs.',
    //     question: 'Is my corporation receiving annual reimbursements for benefit premiums that have been funded but remain unclaimed?'
    //   },
    //   {
    //     title: 'Update important legal documents.',
    //     question: 'Have I updated important legal documents like my primary and secondary Will(s) and/or my shareholder agreement to accomodate the current status of my wealth and future goals?'
    //   },
    //   {
    //     title: 'The right combination of human captial.',
    //     question: 'Are my current advisors independent fiduciaries, and do I have the appropriate personal capital in place to take my corporation and family to the next level of wealth?'
    //   },
    //   {
    //     title: 'Employ elite level human resources. Transfer wealth efficiently.',
    //     question: 'Are my family and corporation positioned properly to handle the transfer or sale of my business interests and wealth?'
    //   },
    //   {
    //     title: 'Support critical solutions.',
    //     question: 'Does my shareholder agreement efficiently accomodate the use of corporately owned life insurance?'
    //   }
    // ];

  }

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }
  workVoluntaryLifestyleModal() {
    this.modalRef = this.modalService.show(WorkVoluntaryLifestyleModalComponent, Object.assign({}, { class: 'default work-voluntary-modal' }));
  }
  toggleFlip1() {
    this.flip1 = (this.flip1 === 'inactive') ? 'active' : 'inactive';
  }
  toggleFlip2() {
    this.flip2 = (this.flip2 === 'inactive') ? 'active' : 'inactive';
  }
  toggleFlip3() {
    this.flip3 = (this.flip3 === 'inactive') ? 'active' : 'inactive';
  }
  toggleFlip4() {
    this.flip4 = (this.flip4 === 'inactive') ? 'active' : 'inactive';
  }
  toggleFlip5() {
    this.flip5 = (this.flip5 === 'inactive') ? 'active' : 'inactive';
  }
  toggleFlip6() {
    this.flip6 = (this.flip6 === 'inactive') ? 'active' : 'inactive';
  }
  toggleFlip7() {
    this.flip7 = (this.flip7 === 'inactive') ? 'active' : 'inactive';
  }
  toggleFlip8() {
    this.flip8 = (this.flip8 === 'inactive') ? 'active' : 'inactive';
  }
  toggleFlip9() {
    this.flip9 = (this.flip9 === 'inactive') ? 'active' : 'inactive';
  }

}
