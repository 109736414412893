import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';
import { CalconicModalComponent } from '../components/calconic-modal/calconic-modal.component';
import { WorkVoluntaryFundModalComponent } from '../components/work-voluntary-fund-modal/work-voluntary-fund-modal.component';
import { ActivatedRoute } from '@angular/router';
import { WorkVoluntaryLifestyleModalComponent } from '../components/work-voluntary-lifestyle-modal/work-voluntary-lifestyle-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService, private route: ActivatedRoute) {}

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

  fasterModal() {
    this.modalRef = this.modalService.show(CalconicModalComponent, Object.assign({}, { class: 'default calconic-modal' }));
    this.modalRef.content.fasterYounger = true;
  }

  onAnchorClick() {
    setTimeout(() => {
      const anchor = document.getElementById('startEnjoy');
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView({  block : 'center', behavior: 'smooth' });
      }
    });
  }

  onAnchorClickMobile() {
    setTimeout(() => {
      const anchor = document.getElementById('startEnjoyMobile');
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView({  block : 'start', behavior: 'smooth' });
        // const element = document.getElementById('startEnjoyMobile');
        // element.classList.add('startEnjoyMobile-spacing');

      }
    });
  }

  ngOnInit() {}

  workVoluntaryFundModal() {
    this.modalRef = this.modalService.show(WorkVoluntaryFundModalComponent, Object.assign({}, { class: 'default work-voluntary-modal' }));
  }
  workVoluntaryLifestyleModal() {
    this.modalRef = this.modalService.show(WorkVoluntaryLifestyleModalComponent, Object.assign({}, { class: 'default work-voluntary-modal' }));
  }

}
