import { Component, OnInit } from '@angular/core';
import Typed from "typed.js";

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {
  typed: Object;
  constructor() {


  }

  ngOnInit() {
    const options = {
      //   strings: [
      //     'LEGAL',
      //   ],
      //   typeSpeed: 200,
      //   backSpeed: 100,
      //   showCursor: false,
      //   cursorChar: '|',
      //   loop: true
      // };
      //
      // this.typed = new Typed('#typed-is-awesome', options);
    };
  }

}
