import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cash-flow-modal',
  templateUrl: './cash-flow-modal.component.html',
  styleUrls: ['./cash-flow-modal.component.scss']
})
export class CashFlowModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

}
