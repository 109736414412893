import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';
import { CalconicModalComponent } from '../components/calconic-modal/calconic-modal.component';

@Component({
  selector: 'app-faster',
  templateUrl: './faster.component.html',
  styleUrls: ['./faster.component.scss']
})
export class FasterComponent implements OnInit {
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

  fasterModal() {
    this.modalRef = this.modalService.show(CalconicModalComponent, Object.assign({}, { class: 'default calconic-modal' }));
    this.modalRef.content.fasterYounger = true;
  }

}
