import { Component, OnInit } from '@angular/core';
import { AssurancePlanningModalComponent } from '../components/assurance-planning-modal/assurance-planning-modal.component';
import { CashFlowModalComponent } from '../components/cash-flow-modal/cash-flow-modal.component';
import { HistoricalReturnsModalComponent } from '../components/historical-returns-modal/historical-returns-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-related-material',
  templateUrl: './related-material.component.html',
  styleUrls: ['./related-material.component.scss']
})
export class RelatedMaterialComponent implements OnInit {
  modalRef: BsModalRef;
  constructor( private modalService: BsModalService) {
  }

  ngOnInit() {

  }
  cashFlowModal() {
    this.modalRef = this.modalService.show(CashFlowModalComponent, Object.assign({}, { class: 'default cash-flow-modal' }));
  }
  historicalModal() {
    this.modalRef = this.modalService.show(HistoricalReturnsModalComponent, Object.assign({}, { class: 'default related-material-modal' }));
  }
  // AssurancePlanningModal() {
  //   this.modalRef = this.modalService.show(AssurancePlanningModalComponent, Object.assign({}, { class: 'default assurance-planning-modal' }));
  // }

}
