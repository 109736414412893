import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2 } from 'angulartics2';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from './components/get-started-modal/get-started-modal.component';
import { RelatedMaterialsModalComponent } from './components/related-materials-modal/related-materials-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loader = true;
  modalRef: BsModalRef;
  expanded;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private angulartics2: Angulartics2,
    private modalService: BsModalService
  ) {

    setTimeout(() => {
      this.loader = false;
      const preloaderEl = document.getElementById('preloader');
      preloaderEl.classList.remove('preloader-fixed');
    }, 2000);

    this.angulartics2GoogleAnalytics.startTracking();

  }

  onAnchorClick() {
    this.route.fragment.subscribe ( f => {
      const element = document.querySelector ( '#' + f );
      if ( element ) {
        element.scrollIntoView ({ block: 'start',  inline: 'start', behavior: 'smooth' });
      }
    });
  }

  // just for calculator link destination
  calculator(anchorHash) {
    setTimeout(() => {
      const anchor = document.getElementById(anchorHash);
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView({ block: 'start',  inline: 'start', behavior: 'smooth' });
      }
    });
  }

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }

  relatedMaterialsModal() {
    this.modalRef = this.modalService.show(RelatedMaterialsModalComponent, Object.assign({}, { class: 'default resources-started-modal' }));
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0);
    });

    // this.router.events.subscribe(s => {
    //   if (s instanceof NavigationEnd) {
    //     const tree = this.router.parseUrl(this.router.url);
    //     if (tree.fragment) {
    //       const element = document.querySelector('#' + tree.fragment.replace(/(#|\/)/g, ''));
    //       if (element) {
    //         element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    //       }
    //     }
    //   }
    // });

    this.router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment && !tree.fragment.match(/(#|\/)/g) ) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) {
            element.scrollIntoView ({ block: 'start',  inline: 'start', behavior: 'smooth' });
          }
        }
      }
    });

  }

  googleTrack(link) {
    this.angulartics2.eventTrack.next({
        action: 'pageview',
        properties: {
            pageSection: link
        }
    });
  }
  toggle(){
    this.expanded = !this.expanded;
  }

}
