import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-calconic-modal',
  templateUrl: './calconic-modal.component.html',
  styleUrls: ['./calconic-modal.component.scss']
})
export class CalconicModalComponent implements OnInit {

  fasterYounger;
  cashFlow;

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit() {

  }

}
