import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GetStartedModalComponent } from '../components/get-started-modal/get-started-modal.component';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {
  modalRef: BsModalRef;
  showSelected1: boolean = false;
  showSelected2: boolean = false;
  showSelected3: boolean = false;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  getStartedModal() {
    this.modalRef = this.modalService.show(GetStartedModalComponent, Object.assign({}, { class: 'default get-started-modal' }));
  }
  show1() {
    this.showSelected1 = !this.showSelected1;
  }
  show2() {
    this.showSelected2 = !this.showSelected2;
  }
  show3() {
    this.showSelected3 = !this.showSelected3;
  }
}
